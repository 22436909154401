
export default [
    {
        anoBike: 2024
    },
    {
        anoBike: 2023
    },
    {
        anoBike: 2022
    },
    {
        anoBike: 2021
    },
    {
        anoBike: 2020
    },
    {
        anoBike: 2019
    },
    {
        anoBike: 2018
    },
    {
        anoBike: 2017
    },
    {
        anoBike: 2016
    },
    {
        anoBike: 2015
    },
    {
        anoBike: 2014
    },
    {
        anoBike: 2013
    },
    {
        anoBike: 2012
    },
    {
        anoBike: 2011
    },
    {
        anoBike: 2010
    },
    {
        anoBike: 2009
    }
]